import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

import { useIntl } from "gatsby-plugin-react-intl";

const Seo = ({ title, description, image, article }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const intl = useIntl();

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  const locales = ["en", "da"];

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta content={seo.description} htmlAttributes={{ lang: intl.locale }} />
      <meta name="image" content={seo.image} />
      {/* Analytics */}
      <script
        src="https://growing-floyd.reel.energy/script.js"
        data-site={process.env.FATHOM_DATA_SITE}
        defer
      ></script>

      <script type="text/javascript" defer>
        {`
        window.onload=function() {
          document.querySelectorAll('.app-signup-button').forEach(item => {
            item.addEventListener('click', event => {
              fathom.trackGoal('7I0PFUCU', 0);
            });

          });
          document.querySelectorAll('#email-signup').forEach(item => {
            item.addEventListener('submit', event => {
              fathom.trackGoal('GKYRBU38', 0);
            })
          });
        };
        `}
      </script>

      {/* Cookie Banner */}
      <script type="text/javascript">
        {`
          var _iub = _iub || [];
          _iub.csConfiguration = {"consentOnContinuedBrowsing":false,"ccpaAcknowledgeOnDisplay":true,"whitelabel":false,"lang":"en","siteId":2334990,"enableCcpa":true,"countryDetection":true,"cookiePolicyId":72778846, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"float-bottom-left","acceptButtonColor":"#7553ff","acceptButtonCaptionColor":"white","customizeButtonColor":"#212121","customizeButtonCaptionColor":"white","rejectButtonColor":"#7553ff","rejectButtonCaptionColor":"white","textColor":"#010101","backgroundColor":"#ffffff" }};
          `}
      </script>
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/ccpa/stub.js"
      ></script>
      <script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        charset="UTF-8"
        async
      >
        {" "}
      </script>

      {/* fonts */}
      <link rel="preconnect" href="https://fonts.gstatic.com" />

      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,500&display=swap"
        rel="stylesheet"
      />

      {/* Hubspot form */}
      <script charset="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js"></script>


      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {locales.map((locale) => {
        return (
          <link
            rel="alternate"
            href={"http://reel.energy/" + locale}
            hrefLang={locale}
            key={locale}
          />
        );
      })}
    </Helmet>
  );
};

export default Seo;

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
